
import './DataRuns.css'
import * as React from "react";
import ControlPanelContainer from '../../container/ControlPanelContainer';
import GridLayout from "react-grid-layout";

import ChatGPTPanelContainer from '../../container/ChatGPTPanelContainer';
import LoginPanelContainer from '../../container/LoginPanelContainer';

export interface IProps {
    contentWidth:number,
    contentHeight:number
}
export interface IState {
    layout_config: any,
    screenWidth: number,
    screenHeight: number
}

export default class DataRuns extends React.Component<IProps, IState>{
    public ChatGPTPanelRef: any;
    constructor(props:IProps) {
        super(props);
        this.onResizeStop = this.onResizeStop.bind(this);
        this.getLayoutConfigWithName = this.getLayoutConfigWithName.bind(this);
        this.getCurrentLayoutConfig = this.getCurrentLayoutConfig.bind(this);
        this.ChatGPTPanelRef = React.createRef();
        this.state = {
            layout_config: null,
            screenWidth : 0,
            screenHeight: 0
        }

        //this.resize.bind(this);
        // Flow:
        // 1. Constructor
        // 2. componentWillMount()
        // 3. render()
        // 4. componentDidMount()
        // If props update:
        // 4.1 componentWillReceiveProps(nextProps : IProps), then goto 5.
        // If States update
        // 5. shouldComponentUpdate() if return false, then no rerendering.
        // 6. if True, then componentWillUpdate
        // 7. render()
        // 8. componentDidUpdate
        // If Unmount, then componentWillUnmount()
    }


     // When the view is mounted, it will be executed.
     componentDidMount(){
        //window.addEventListener('resize', this.onResize)
         this.setState({
             layout_config: this.getWholeLayoutConfig(),
             screenHeight: window.innerHeight,
             screenWidth: window.innerWidth
         })
     }

     // Get width and height from view name. 
     public getLayoutConfigWithName(name:string){
         let width = 0;
         let height = 0;
         if(name === "ChatGPTPanel"){
             if(this.ChatGPTPanelRef && this.ChatGPTPanelRef.current){
                 width = this.ChatGPTPanelRef.current.offsetWidth;
                 height = this.ChatGPTPanelRef.current.offsetHeight;
             }
         }

         return {
             "width":width,
             "height":height
         }
     }
     // Get the whole layout config. 
     public getWholeLayoutConfig(){

        let viewName = ["ChatGPTPanel"]; 
        let layout_config:any = {};
        viewName.forEach((d:any)=>{
            layout_config[d] = this.getLayoutConfigWithName(d);
        })

        return layout_config;
     }

     // Get layout config from view name. 
     public getCurrentLayoutConfig(name:string){
         let layout_config = this.state.layout_config;
        if(layout_config){
            if(layout_config[name]){
                return layout_config[name];
            }else{
                return null;
            }
        }else{
            return null;
        }
     }

     // Handling the changing of states or props.
     componentDidUpdate(prevProps:IProps, prevState:IState) {
        

        // If the window is resized, update the layout config. 
        if(prevProps.contentHeight!==this.props.contentHeight
            || prevProps.contentWidth !== this.props.contentWidth){
                this.setState({
                    layout_config: this.getWholeLayoutConfig()
                })
            }   
     }

    // RESERVED: handling the layout change.
    public onLayoutChange(layout:any){
        console.log("Layout", layout);
    }
    // For react-grid-layout, when the resizing is fixed, the layout configuration should be updated.
    public onResizeStop(layout:any){
        console.log("onResizeStop", layout);
        console.log("Layout", this.getWholeLayoutConfig());
        this.setState({
            layout_config : this.getWholeLayoutConfig()
        })
        //var width = document.getElementById('a').offsetWidth;
    }
    public render() {
        // Rendering. 

        // layout is an array of objects, see the demo for more complete usage
        let enableStatic = true;  // If enabled static, the layout cannot be manually configured.
        let max_row_num = Math.floor(this.props.contentHeight / 40); // Maximum rows in the screen.
        // small width, height: 1707 724
        // big width, height: 2560 1175
        let ControlPanelH = max_row_num;
        let GraphViewPanelH = max_row_num;
        
        let layout = [
            {i: 'b', x: 0, y: 0, w: 24, h: GraphViewPanelH, static:enableStatic}, // Graph View
        ];
        
        let generateChatGPTPanel = (width:number, height:number) => {
            return <ChatGPTPanelContainer 
                width={width}
                height={height}
                />
        }
        // Generate Whole Layout.
        let generateWholeView = () =>{
            let screenwidth = window.innerWidth;
            //let screenheight = window.innerHeight;

            return <div>
                <GridLayout className="layout" layout={layout} 
                cols={24} rowHeight={30} width={screenwidth} onLayoutChange={this.onLayoutChange}
                onResizeStop={this.onResizeStop}>
                    <div className="PanelBox" key="b"  >
                        <div style={{width:"100%", height:"100%"}} ref={this.ChatGPTPanelRef}>
                        {(this.getCurrentLayoutConfig("ChatGPTPanel"))?
                            generateChatGPTPanel(
                                this.getCurrentLayoutConfig("ChatGPTPanel")["width"], 
                                this.getCurrentLayoutConfig("ChatGPTPanel")["height"]
                            )
                            :
                            <div />
                        }
                        </div>
                    </div>
                </GridLayout>
                {(this.getCurrentLayoutConfig("ChatGPTPanel"))?<LoginPanelContainer/>:
                            <div />}

                </div>
        }
        
        return generateWholeView();
    }
}

