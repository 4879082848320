import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../types';
import LoginPanel from '../components/DataRuns/LoginPanel';


const mapStateToProps = (state: StoreState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);



