var initMetaData = () => {
    console.log("not initialized");
}
var updateDialogueInfo = () => {
    console.log("not initialized");
}
function set_initMetaData(callback: any){
    initMetaData = callback;
}

function get_initMetaData(){
    return initMetaData;
}

function set_updateDialogueInfo(callback: any){
    updateDialogueInfo = callback;
}

function get_updateDialogueInfo(){
    return updateDialogueInfo;
}
export {
    set_initMetaData,
    get_initMetaData,
    set_updateDialogueInfo,
    get_updateDialogueInfo
}