import { DIALOGUE_ID_CHANGE} from '../constants';
import {StoreState} from '../types';
const initial_state : StoreState = {
    dialogue_id : 1,
    
}
// 处理并返回 state 
export default (state = initial_state, action:any): StoreState => {
   
    switch (action.type) {
      case DIALOGUE_ID_CHANGE:
        // Change dataset id
        return {
          ...state,
          dialogue_id: action.dialogue_id,
        };
      default:
        return state
    }
}


//import { DECREMENT, INCREMENT } from '../constants';

