import axios from 'axios';

const ENABLE_STATIC_JSON = false;
const ENABLE_PUBLIC_URL = false;
const PUBLIC_URL = "http://diagvis.s58.hkustvis.org";
const PRIVATE_URL = "http://localhost:7777";
const CHOICE_URL = (ENABLE_PUBLIC_URL)?PUBLIC_URL:PRIVATE_URL;
export const ENABLE_FORCE_AUTH = true;
let TOKEN:any = "";

// Remote Backend.
const URL = process.env.NODE_ENV === 'development'
    ? CHOICE_URL
    : window.location.origin;
const axiosInstance1 = axios.create({
    baseURL: `${URL}/api/`,
    // timeout: 1000,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

// Load Static Data.
const URL2 = window.location.origin;
const axiosInstance2 = axios.create({
    baseURL: `${URL2}/data/`,
    // timeout: 1000,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

let axiosInstance = (ENABLE_STATIC_JSON)?axiosInstance2:axiosInstance1;


export async function getUserVerify(username: any, password:any): Promise<any> {
    let url = `/user_verify`;
    const params = { username, password};
    const res = await axiosInstance.get(url, {params}); // EXCEPTION
    //const params = { classifier_start, classifier_end };
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}
function wrap_params_with_token(params: any){
    if(ENABLE_FORCE_AUTH){
        const new_params:any = {
            token: TOKEN,
            ...params
        };
        return new_params;
    }else{
        return params;
    }
}
function wrap_formData_with_token(formdata: any){
    if(ENABLE_FORCE_AUTH){
        formdata.append('token', TOKEN)
        return formdata;
    }else{
        return formdata;
    }
}
function wrap_URL_with_token(URL: any){
    if(ENABLE_FORCE_AUTH){
        let newURL = URL + "&token=" + TOKEN
        return newURL;
    }else{
        return URL;
    }
}
function wrap_UploadURL_with_token(URL: any){
    if(ENABLE_FORCE_AUTH){
        let newURL = URL + "?token=" + TOKEN
        return newURL;
    }else{
        return URL;
    }
}
function wrap_ret_pkg(pkg:any, key:any){
    if(ENABLE_STATIC_JSON){
        let ret_pkg:any = {
            "success": true
        }
        ret_pkg[key] = pkg;
        return ret_pkg;
    }else{
        return pkg;
    }
}
export function setToken(token:any) {
    TOKEN = token;
}







// Read graph dataset metainfo.
export async function getMetaData(): Promise<any> {
    let url = `/metadata`;
    const params = wrap_params_with_token({});
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

export async function getDialogueInfo(id:number): Promise<any> {
    let url = '/dialogue'
    const params = wrap_params_with_token({id}); 
    const res = await axiosInstance.get(url, {params} );
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

