import * as React from "react";
import { Modal, Button, Input, Row, message, Form, Col, Select} from 'antd';
import {getUserVerify, setToken, ENABLE_FORCE_AUTH} from '../../../service/dataService'
import { getCookie, setCookie } from "../../../helper/cookieManager";
import { get_initMetaData, get_updateDialogueInfo } from "../../../helper/callbackHook";

export interface IProps {

}
export interface IState {
  loading: boolean,
  user_identifier: string,
  LoginPanelModal_visible: any
  username: string,
  password: string,
  initial_options: any
}
const { TextArea } = Input;

export default class LoginPanel extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
        let initial_username : any= getCookie("username");
        let initial_password : any= getCookie("password");
        this.state = {
          loading: false,
          LoginPanelModal_visible: ENABLE_FORCE_AUTH,
          user_identifier: "",
          username: initial_username,
          password: initial_password,
          initial_options: "empty"
        }
        //this.resize.bind(this);
        // Flow:
        // 1. Constructor
        // 2. componentWillMount()
        // 3. render()
        // 4. componentDidMount()
        // If props update:
        // 4.1 componentWillReceiveProps(nextProps : IProps), then goto 5.
        // If States update
        // 5. shouldComponentUpdate() if return false, then no rerendering.
        // 6. if True, then componentWillUpdate
        // 7. render()
        // 8. componentDidUpdate
        // If Unmount, then componentWillUnmount()
    }
    handleOk = () => {
          this.initStage();
      };
    
    handleCancel = (e:any) => {
          this.initStage();
      };

    initFinalStage = () => {
      let username = this.state.username;
      let password = this.state.password;
      setCookie("username", username);
      setCookie("password", password)
      this.setState({
        LoginPanelModal_visible: false
      })
      let initMetaData = get_initMetaData();
      initMetaData();
      let updateDialogueInfo = get_updateDialogueInfo();
      updateDialogueInfo();
    }
    initStage = () => {

          let username = this.state.username;
          let password = this.state.password;
          if(!ENABLE_FORCE_AUTH){
            this.initFinalStage();
          }else{
            this.UserVerify(username, password, this.initFinalStage);
          }
      }



    onChangeUserName = (e:any) => {
      this.setState({
        username: e.target.value
      })
  }

  onChangePassWord = (e:any) => {
      this.setState({
          password: e.target.value
      })
  }
    public UserVerify(username:any, password:any, callback: any){
       getUserVerify(username, password).then((user_verify_package:any)=>{
              //console.log(datasetList_package);
              if(user_verify_package["success"] === true){
                if(user_verify_package.hasOwnProperty("token")){
                    let token = user_verify_package["token"];
                    setToken(token)
                    callback();
                }
            }else{
                if(user_verify_package.hasOwnProperty("info")){
                    message.error(user_verify_package["info"]);
                }
            }
        }).catch((error:any)=>{
          message.error(""+error);
        });

        
    }
    public componentDidMount(): void {
      if(!ENABLE_FORCE_AUTH){
        this.initFinalStage();
      }
    }
    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {


    }
    public render() {
      let handleOK = this.handleOk;
        function handleKeyUp(event:any) {
            // Enter
            if (event.keyCode === 13) {
                handleOK()
            }
          }
        
        return  (      
        <Modal
            title="User Study"
            className={"LoginPanel"}
            visible={this.state.LoginPanelModal_visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="OK" type="primary" onClick={this.handleOk}>
                  OK
                </Button>
              ]}
        >
          <Row>
            <Col span={24}>
            <Form
                        name="basic"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onKeyUp={handleKeyUp}
                        >
                          {(ENABLE_FORCE_AUTH)?(
                            <div>
                        <Form.Item
                            label="Username"
                        >
                            <Input onChange={this.onChangeUserName} value={this.state.username}/>
                        </Form.Item>
                    
                        <Form.Item
                            label="Password"
                        >
                            <Input.Password onChange={this.onChangePassWord} value={this.state.password}/>
                        </Form.Item></div>):<div/>}
                        </Form>
            </Col>
          </Row>



        </Modal>
        )
    }
}

