
import * as React from "react";
import { InputNumber, message} from 'antd';
import { getMetaData } from "../../../service/dataService";
import { set_initMetaData } from "../../../helper/callbackHook";

export interface IProps {
    current_page: number,
    setCurrentPage: any
}

export interface IState {
    total_pages: number,
}



export default class DataSelector extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);        
        this.state = {
            total_pages: 1
        }

    }


    public componentDidMount(): void {
        set_initMetaData(this.updateMetaData);
    }
    updateMetaData = () => {
        getMetaData().then((results:any)=>{
            if(results["success"]){
                let metadata = results["metadata"];
                this.setState({
                    total_pages: metadata["total_page"]
                })
            }
            console.log("getMetaData", results)
            }).catch((reason:any)=>{
            message.error(""+reason);
            });
    }

    onPageChange = (event: any) => {
        this.props.setCurrentPage(event);
    }
    public render() {
        

          return (
            <div style={{float:'right', fontFamily: "sans-serif", fontSize: "16px"}} >
              <InputNumber min={1} max={this.state.total_pages} value={this.props.current_page}  size="small" onChange={this.onPageChange}/> / {this.state.total_pages}
              
              </div>
            );
       

    }
}

